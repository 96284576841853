import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    classificationSchemaId: Number,
  };

  connect() {
    this.formatJson();
  }

  formatJson() {
    const sampleRequestBody = {
      content: "Sample content",
      metadata: {
        key1: "value1",
      },
      classification_schema_id: this.classificationSchemaIdValue,
    };

    // Create the JSON string from the sampleRequestBody
    const jsonString = JSON.stringify(sampleRequestBody, null, 4);
    // Set the JSON string as the html element's value
    this.element.value = jsonString;
  }
}
